import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import userReducer from 'features/user'
import studentsReducer from 'features/student'
import processReducer from 'features/processMonitor'
import announcementsReducer from 'features/announcements'
import eventsReducer from 'features/events'

const persistConfig = {
    key: 'vine',
    storage,
    whitelist: ['user']
}

const rootReducer = combineReducers({
    user: userReducer,
    students: studentsReducer,
    monitor: processReducer,
    announcements: announcementsReducer,
    events: eventsReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer
})

export let persistor = persistStore(store)

export default store
