import React, { useState } from 'react';
import Bell from 'assets/icons/bell.svg'
import Cog from 'assets/icons/gear.svg'
import Call from 'assets/icons/Call.svg'
import Location from 'assets/icons/Location.svg'
import Calender from 'assets/icons/calendar.svg'
import User from 'assets/icons/user.svg'
import Progress from 'assets/icons/Activity.svg'
import Spinner from 'assets/spinner.svg'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import appwriteSdk from 'backend/config'
import { v4 as uuidv4 } from 'uuid';
import DetailsCard from 'components/cards/index';
import { CustomInput, CustomTextBox, CustomSelect } from 'components/inputs'
import { Error, Success } from 'components/notifiers'
import { useSelector, useDispatch } from 'react-redux';
import { addStudent } from 'features/student';




const StudentDetails = ({ studentId }) => {
    const { students, parents } = useSelector(state => state.students);
    const studentsDetails = students.filter(t => t.studentID === studentId)[0]
    const parentsDetails = parents.filter(u => u.studentID === studentId)[0]

    return <div className='w-full shadow-lg bg-[#ffffff]'>
        <div className='w-full h-[150px] bg-detailcover bg-cover' />
        <div className='w-full mb-10 flex flex-row flex-wrap p-5 rounded-lg shadow-lg bg-[#ffffff]'>
            <div className='basis-1/2 px-8 border-r-[1.5px] border-slate-400'>
                <div className='flex flex-col'>
                    <h5 className='text-2xl text-slate-800 capitalize font-semibold'>student</h5>
                    <div className='flex flex-row flex-wrap'>
                        {
                            [
                                { label: 'first name', value: studentsDetails.firstName, icon: User },
                                { label: 'last name', value: studentsDetails.lastName, icon: User },
                                { label: 'email', value: studentsDetails.email, icon: Call },
                                { label: 'phone', value: studentsDetails.phone, icon: Call },
                                { label: 'address', value: studentsDetails.address, icon: Location },
                                { label: 'date of birth', value: studentsDetails.dob, icon: Calender },
                                { label: 'grade', value: `grade ${studentsDetails.grade}`, icon: Progress },
                            ].map((t, index) => <div key={index} className='basis-1/2 flex flex-col my-4'>
                                <h5 className='text-base text-slate-600 font-semibold capitalize text-opacity-70 ml-2'>{t.label}</h5>
                                <div className='inline-flex items-center mt-3'>
                                    <div className='h-[35px] w-[35px] flex justify-center items-center bg-orange rounded-full'>
                                        <img src={t.icon} className='h-[25px] w-[25px]' style={{ filter: 'brightness(0) invert(1)' }} />
                                    </div>
                                    <h5 className='text-base capitalize text-opacity-70 ml-2 '>{t.value}</h5>
                                </div>
                            </div>)
                        }
                    </div>
                </div>
            </div>
            <div className='basis-1/2 px-8'>
                <div className='flex flex-col'>
                    <h5 className='text-2xl text-slate-800 capitalize font-semibold'>parent/guardian</h5>
                    <div className='flex flex-row flex-wrap'>
                        {
                            [
                                { label: 'first name', value: parentsDetails.firstName, icon: User },
                                { label: 'last name', value: parentsDetails.lastName, icon: User },
                                { label: 'email', value: parentsDetails.email, icon: Call },
                                { label: 'phone', value: parentsDetails.phone, icon: Call },
                                { label: 'address', value: parentsDetails.address, icon: Location },
                            ].map((t, index) => <div key={index} className='basis-1/2 flex flex-col my-4'>
                                <h5 className='text-base text-slate-600 font-semibold capitalize text-opacity-70 ml-2'>{t.label}</h5>
                                <div className='inline-flex items-center mt-3'>
                                    <div className='h-[35px] w-[35px] flex justify-center items-center bg-orange rounded-full'>
                                        <img src={t.icon} className='h-[25px] w-[25px]' style={{ filter: 'brightness(0) invert(1)' }} />
                                    </div>
                                    <h5 className='text-base capitalize text-opacity-70 ml-2 '>{t.value}</h5>
                                </div>
                            </div>)
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>

}

export default StudentDetails