import React, { useState, useEffect } from 'react';
import { getEvents } from 'features/events';
import { useDispatch, useSelector } from 'react-redux';
import CreateEventModal from 'modules/events/createEventModal';
import EventDetailsModal from 'modules/events/eventDetailsModal';
import { CapitalizeFirstLetter, formatDate } from '../../helpers';

const Events = () => {
    const dispatch = useDispatch()
    const { events } = useSelector(state => state.events)

    const [modalOpen, setModalOpen] = useState(false)
    const [detailsModalOpen, setDetailsModalOpen] = useState(false)

    useEffect(() => {
        dispatch(getEvents())
        return () => { };
    }, []);

    // if (typeof events === undefined || events.length < 1) return null;

    return (
        <>
            <div className='text-right mb-2 h-12'>
                <button className='px-5 py-2 border-2 border-violet rounded-3xl text-violet capitalize' onClick={() => setModalOpen(true)}>
                    add event
                </button>
            </div>
            <CreateEventModal modalOpen={modalOpen} handleClose={() => setModalOpen(false)} />
            <div className='min-h-[calc(100%-56px)] bg-[#fff]'>
                {
                    (events && events.length > 0) ? <div className='grid grid-cols-4 gap-4 px-8 pt-8 pb-12'>
                        {
                            events.map(({ audiance, $id, details, createdAt }) => <div key={$id}>
                                <div className='h-[250px] w-full cursor-pointer' onClick={() => setDetailsModalOpen(true)}>
                                    <div className='h-[80%] flex rounded-lg justify-center items-center w-full bg-violet'>
                                        <h3 className='capitalize text-white text-3xl font-semibold'>{formatDate(createdAt)}</h3>
                                    </div>
                                    <div className='h[20%] w-full overflow-x-hidden mt-3'>
                                        <h5 className='text-blue text-opacity-60 text-lg capitalize'>{`grade ${audiance}`}</h5>
                                        <span className='text-md text-gray-500 line-clamp-1'>{CapitalizeFirstLetter(details)}</span>
                                    </div>
                                </div>
                            </div>)
                        }
                    </div> : <div className='bg-[#fff] h-full py-8 grid place-content-center place-items-center capitalize text-gray-500 font-semibold text-lg'>No events found</div>
                }
            </div>
        </>
    );
}

export default Events;
