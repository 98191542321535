import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { databases, account } from 'backend/config';
import { ID } from 'appwrite'
import { setFetching, setLoading, setError, setSuccess, resetMessage } from 'features/processMonitor'
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

const initialState = {
    user: undefined,
    users: [],
    loading: false,
    success: false,
    error: undefined,
    currentRoute: 'dashboard'
}


export const userLogin = createAsyncThunk('user/userLogin', async ({ email, password }, thunkAPI) => {
    const notification = toast.loading('Loggin in ...', {toastId: 'user-login-notification'})
    try {
        await account.createEmailSession(email, password);
        let userSession = await account.get()
        Cookies.set(process.env.REACT_APP_COOKIE, JSON.stringify(userSession))
        toast.update(notification, {type: 'success', render: 'User logged in successfully', autoClose: 2000})
        return userSession;
    } catch (error) {
        toast.update(notification, {type: 'error', render: error.message, autoClose: 2000})
        return thunkAPI.rejectWithValue(error.message);
    }
})

export const addUser = createAsyncThunk('user/addUser', async(_, user) => {
    const notification = toast.loading('Adding user ...', {toastId: 'add-user-notification'})
    try {
        // toast.update(notification, {type: 'error', render: error.message, autoClose: 2000})
    } catch (error) {
        toast.update(notification, {type: 'error', render: error.message, autoClose: 2000})
    }
})

export const getUsers = createAsyncThunk('users/getUser', async (_, thunkAPI) => {
    try {
        thunkAPI.dispatch(setFetching())
        let users = await databases.listDocuments(process.env.REACT_APP_DB, 'users')
        thunkAPI.dispatch(setSuccess())
        return users.documents;
    } catch (error) { 
        thunkAPI.dispatch(setError(error.message))
    }
})

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        switchroute(state, { payload }) {
            state.currentRoute = payload
        },
        logout(state) {
            state.users = undefined;
            state.loading = false
            state.error = false
            state.success = false
        }
    },
    extraReducers: {
        [userLogin.fulfilled]: (state) => {
            state.success = true
        },
        [getUsers.fulfilled]: (state, { payload }) => {
            state.users = payload;
        }
    }
})

export const { switchroute, logout } = userSlice.actions
export default userSlice.reducer