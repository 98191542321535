import React, { useState } from 'react';
import Spinner from 'assets/spinner.svg'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import DetailsCard from 'components/cards/index';
import { CustomInput, CustomTextBox, CustomSelect } from 'components/inputs'
import { Error, Success } from 'components/notifiers'
import { useSelector, useDispatch } from 'react-redux';
import { addStudent } from 'features/student';
import ImageCrop from 'components/imageResizer/index';
import passport from 'assets/images/passport place holder.jpeg'

const CreateStudent = () => {

    const [imageSelector, setImageSelector] = useState(false)
    const {loading, success, error} = useSelector(state => state.monitor)

    const dispatch = useDispatch()
    const studentInitialDetails = {
        firstName: '',
        lastName: '',
        dob: '',
        placeOfBirth: '',
        studyLevel: '',
        email: '',
        phone: '',
        address: '',
        parentFirstName: '',
        parentLastName: '',
        parentEmail: '',
        parentPhone: '',
        parentAddress: ''
    }

    const studentDetailsValidationSchema = Yup.object({
        firstName: Yup.string().required('first name is required'),
        lastName: Yup.string().required('last name is required'),
        dob: Yup.string().required('date of birth is required'),
        placeOfBirth: Yup.string().required('place of birth is required'),
        studyLevel: Yup.string().required('student\'s study level is required'),
        email: Yup.string().required('email is required'),
        phone: Yup.string().required('phone number is required'),
        address: Yup.string().required('address is required'),
        parentFirstName: Yup.string().required('parent\'s first name is required'),
        parentLastName: Yup.string().required('parent\'s last name is required'),
        parentEmail: Yup.string().required('parent\'s email is required'),
        parentPhone: Yup.string().required('parent\'s phone number is required'),
        parentAddress: Yup.string().required('parent\'s address is required')
    })

    const submitHandler = (values) => dispatch(addStudent(values))

    return <Formik
        initialValues={studentInitialDetails} validationSchema={studentDetailsValidationSchema}
        onSubmit={(values) => submitHandler(values)}>
        <Form>
            <DetailsCard title="Student's details">
                <div className='basis-2/12 cursor-pointer' onClick={() => setImageSelector(true)}>
                    <label className="block mb-1 text-[17px]">Photo</label>
                    <div className='h-[150px] w-full border-2 px-2 cursor-pointer border-grey rounded-lg flex flex-row justify-center items-center'>
                        <h6 className='font-extralight'>Click here to select file</h6>
                    </div>
                    <ImageCrop modalOpen={imageSelector} handleClose={() => setImageSelector(false)} />
                </div>
                <div className='basis-5/12 px-5'>
                    <CustomInput name="firstName" label='first name' placeholder='First name' />
                    <div className='flex flex-row'>
                        <div className='basis-1/2'>
                            <CustomInput name='dob' label='date of birth' placeholder='01/01/1990' />
                        </div>
                        <div className='basis-1/2 ml-5'>
                            <CustomInput name="placeOfBirth" label='place of birth' placeholder='Place of birth' />
                        </div>
                    </div>
                    <CustomInput name='email' label='email' placeholder='exmaple@email.com' />
                    <CustomTextBox name='address' label='address' placeholder='Kololo, kampala' />
                </div>
                <div className='basis-5/12 px-5'>
                    <CustomInput name='lastName' label="last name" placeholder='Last Name' />
                    <CustomSelect name='studyLevel' label='study level'>
                        {
                            [...Array(5).keys()].map(k => <option key={k}>{`grade ${k}`}</option>)
                        }
                    </CustomSelect>
                    <CustomInput name='phone' label='phone' placeholder='+256-772-56-58-28' />
                </div>
            </DetailsCard>
            <DetailsCard title="Parent's details">
                <div className='basis-1/2'>
                    <CustomInput name='parentFirstName' label='first name' placeholder='First name' />
                    <CustomInput name='parentEmail' label='email' placeholder='exmaple@email.com' />
                    <CustomTextBox name='parentAddress' label='address' placeholder='Kololo, kampala' />
                </div>
                <div className='basis-1/2 ml-5'>
                    <CustomInput name='parentLastName' label='last name' placeholder='Last name' />
                    <CustomInput name='parentPhone' label='phone' placeholder='+256-772-56-58-28' />
                </div>
            </DetailsCard>
            {error && <Error msg={error} />}
            {success && <Success msg='Student added' />}
            <button className='flex flex-row items-center bg-violet border-0 rounded-3xl px-6 py-2 text-white active:bg-violet active:shadow-lg transition duration-150 ease-in-out' type='submit'>
                {loading && <img src={Spinner} className='w-[40px] h-[40px]' />}
                {loading ? `Saving Student` : `Save Student`}
            </button>
        </Form>
    </Formik>
}

export default CreateStudent