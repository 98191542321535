import React, { useEffect, useState } from 'react';
import Student from 'assets/icons/student.svg'
import { useDispatch, useSelector } from 'react-redux';
import AnnouncementDetailsModal from 'modules/announcements/announcementDetailsModal';
import CreateAnnouncementModal from 'modules/announcements/createAnnouncementModal';
import CreateEventModal from 'modules/events/createEventModal';
import EventDetailsModal from 'modules/events/eventDetailsModal';
import { formatDate, CapitalizeFirstLetter } from '../../helpers'
import { switchroute } from '../../features/user';

const Dashboard = () => {
    const [parents, setparents] = useState(0)
    const [admins, setadmins] = useState(0)
    const [students, setstudents] = useState(0)
    const { announcements } = useSelector(state => state.announcements)
    const { events } = useSelector(state => state.events)
    const { users } = useSelector(state => state.user)

    const dispatch = useDispatch()

    useEffect(() => {
        const _admins = users ? users.filter(user => user.role === 'admin') : 0
        const _students = users ? users.filter(user => user.role === 'student') : 0
        const _parents = users ? users.filter(user => user.role === 'parent') : 0

        setadmins(_admins.length)
        setstudents(_students.length)
        setparents(_parents.length)
        return () => { };
    }, [users]);
    return (
        <div className='w-full h-full'>
            <div className='h-24 grid grid-cols-3 gap-4'>
                {
                    [
                        ['students', students],
                        ['parents', parents],
                        ['admins', admins],
                    ].map((f, index) => <div key={index} className='bg-[#fff] flex justify-center items-center rounded-md shadow-sm'>
                        <div className='px-4'>
                            <img src={Student} alt="Logo" className='h-[45px] w-[45px]' />
                        </div>
                        <div className='inline-block'>
                            <h5 className='text-lg font-bold text-violet opacity-70 capitalize tracking-wider'>{f[0]}</h5>
                            <h5 className='text-2xl text-blue-800 font-semibold'>{f[1]}</h5>
                        </div>
                    </div>)
                }
            </div>
            <div className='grid grid-rows-2 gap-4 mt-4'>
                <div className='bg-[#fff] px-8 pt-6 pb-8'>
                    <div className='grid grid-cols-6 border-b py-3'>
                        <div className='col-span-5'>
                            <h4 className='text-lg font-semibold capitalize tracking-wider'>announcement({announcements ? announcements.length : 0})</h4>
                        </div>
                        <div>
                            <button className='px-8 py-3 rounded-3xl bg-white capitalize' onClick={() => dispatch(switchroute('announcements'))}>all announcements</button>
                        </div>
                    </div>
                    {
                        (announcements && announcements.length > 0) ? <div className='grid grid-cols-4 gap-4 py-5'>
                            {
                                announcements.map(({ audiance, $id, details, createdAt }) => <div key={$id}>
                                    <div className='h-[250px] w-full cursor-pointer' onClick={() => { }}>
                                        <div className='h-[80%] flex rounded-lg justify-center items-center w-full bg-violet'>
                                            <h3 className='capitalize text-white text-3xl font-semibold'>{formatDate(createdAt)}</h3>
                                        </div>
                                        <div className='h[20%] w-full overflow-x-hidden mt-3'>
                                            <h5 className='text-blue text-opacity-60 text-lg capitalize'>{`grade ${audiance}`}</h5>
                                            <h5 className='text-md text-gray-500 line-clamp-1'>{CapitalizeFirstLetter(details)}</h5>
                                        </div>
                                    </div>
                                </div>)
                            }
                        </div> : <div className='py-8 grid place-content-center place-items-center capitalize text-gray-500 font-semibold text-lg'>No announcements found</div>
                    }
                </div>

                <div className='bg-[#fff] px-8 pt-6 pb-8'>
                    <div className='grid grid-cols-6 border-b py-3'>
                        <div className='col-span-5'>
                            <h4 className='text-lg font-semibold capitalize tracking-wider'>events({events ? events.length : 0})</h4>
                        </div>
                        <div>
                            <button className='px-8 py-3 rounded-3xl bg-white capitalize' onClick={() => dispatch(switchroute('events'))}>all events</button>
                        </div>
                    </div>
                    {
                        (events && events.length > 0) ? <div className='grid grid-cols-4 gap-4 py-5'>
                            {
                                events.map(({ audiance, $id, details, createdAt }) => <div key={$id}>
                                    <div className='h-[250px] w-full cursor-pointer' onClick={() => { }}>
                                        <div className='h-[80%] flex rounded-lg justify-center items-center w-full bg-violet'>
                                            <h3 className='capitalize text-white text-3xl font-semibold'>{formatDate(createdAt)}</h3>
                                        </div>
                                        <div className='h[20%] w-full overflow-x-hidden mt-3'>
                                            <h5 className='text-blue text-opacity-60 text-lg capitalize'>{`grade ${audiance}`}</h5>
                                            <h5 className='text-md text-gray-500 line-clamp-1'>{CapitalizeFirstLetter(details)}</h5>
                                        </div>
                                    </div>
                                </div>)
                            }
                        </div> : <div className='py-8 grid place-content-center place-items-center capitalize text-gray-500 font-semibold text-lg'>No events found</div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
