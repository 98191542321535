import Bell from 'assets/icons/bell.svg'
import Cog from 'assets/icons/gear.svg'
import { useDispatch, useSelector } from 'react-redux';
import passport from 'assets/images/passport place holder.jpeg'
import { useEffect, useState } from 'react';
import User from 'assets/icons/user.svg'
import Role from 'assets/icons/role.svg'
import Cookies from 'js-cookie';
import { logout } from '../../features/user';
import { useNavigate } from 'react-router';

const Header = () => {
    const {currentRoute} = useSelector(state => state.user)
    const [user, setuser] = useState()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [showusermodal, setshowusermodal] = useState(false)

    const logoutHandler = () => {
        Cookies.remove(process.env.REACT_APP_COOKIE)
        dispatch(logout())
        navigate('/auth', { replace: true })
    }

    useEffect(() => {
        const user = Cookies.get(process.env.REACT_APP_COOKIE)
        if (user) {
            setuser(JSON.parse(user))
        } else {
            navigate('/auth')
        }
        return () => { };
    }, []);

    return <div className='h-16 bg-[#fff] flex flex-row items-center mb-3 px-8'>
        <div className='basis-4/6'>
            <h4 className='font-semibold text-2xl capitalize tracking-widest'>{currentRoute}</h4>
        </div>
        <div className='basis-2/6 flex flex-row justify-end items-center'>
            <img src={Bell} className='h-[40px] w-[40px] p-2 mr-1 cursor-pointer rounded-full bg-[#fff] ' />
            <img src={passport} className='rounded-full w-10 h-10 cursor-pointer' onClick={() => setshowusermodal(!showusermodal)} />
            {
                showusermodal && user && <div className='z-[500] bg-[#fff] absolute py-2 px-4 shadow-md top-20 right-16 w-64 border border-gray-300'>
                    <div className='w-full overflow-x-hidden py-2 space-y-2'>
                        <div className='container flex flex-row items-center cursor-pointer'>
                            <img src={User} className='h-6 w-6 mr-3 rounded-full' />
                            <span className='text-gray-500 text-sm font-semibold capitalize'>{user['name']}</span>
                        </div>
                        <div className='container flex flex-row items-center cursor-pointer'>
                            <img src={Role} className='h-10 w-10 mr-3 rounded-full' />
                            <span className='text-gray-500 text-sm font-semibold capitalize'>admin</span>
                        </div>
                        <div className='container flex flex-row items-center cursor-pointer'>
                            <img src={Cog} className='h-6 w-6 mr-3 rounded-full' />
                            <span className='text-gray-500 text-sm font-semibold capitalize'>settings</span>
                        </div>
                    </div>
                    <div className='w-full border-t border-gray-300 py-2'>
                        <button className='bg-violet w-full text-[#fff] capitalize border-0 py-2' onClick={() => logoutHandler()}>log out</button>
                    </div>
                </div>
            }
        </div>
    </div>
}


export default Header