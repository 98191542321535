import { useField } from 'formik';

const CustomInput = ({ label, ...props }) => {
    const [field, meta] = useField(props)
    return <div className='w3-full mb-8'>
        <label class="block mb-1 text-[17px] capitalize">{label}</label>
        <input className="w-full h-9 pl-3 pr-15 text-base placeholder-gray-600 bg-inherite border-[1.5px] border-grey rounded-md capitalize" type="text" {...field} {...props} />
        {meta.touched && meta.error ? (<h5 className='text-red-500 mt-1 mb-3 capitalize'>{meta.error}</h5>) : null}
    </div>
}

const CustomTextBox = ({ label, ...props }) => {
    const [field, meta] = useField(props)
    return <div className='w3-full mb-8'>
        <label class="block mb-1 text-[17px] capitalize">{label}</label>
        <textarea className="w-full h-[200px] pl-3 pr-15 text-base placeholder-gray-600 bg-inherite border-[1.5px] border-grey rounded-md capitalize" {...field} {...props} />
        {meta.touched && meta.error ? (<h5 className='text-red-500 mt-1 mb-3 capitalize'>{meta.error}</h5>) : null}
    </div>
}

const CustomSelect = ({ label, children, ...props }) => {
    const [field, meta] = useField(props)
    return <div className='w3-full mb-8'>
        <label class="block mb-1 text-[17px] capitalize">{label}</label>
        <select className="w-full h-9 pl-3 pr-15 text-base placeholder-gray-600 bg-inherite border-[1.5px] border-grey rounded-md capitalize" type="text" {...field} {...props}>
            {children}
        </select>
        {meta.touched && meta.error ? (<h5 className='text-red-500 mt-1 mb-3 capitalize'>{meta.error}</h5>) : null}
    </div>
}

export { CustomInput, CustomTextBox, CustomSelect }