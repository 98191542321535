
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    fetching: false,
    loading: false,
    error: false,
    success: false,
}

const processMonitorSlice = createSlice({
    name: 'processMonitor',
    initialState,
    reducers: {
        setLoading: (state) => {
            state.loading = true;
        },
        setFetching: (state) => {
            state.fetching = true;
        },
        setError: (state, { payload }) => {
            state.error = payload;
            state.loading = false;
        },
        setSuccess: (state) => {
            state.success = true;
            state.loading = false;
        },
        resetMessage: (state) => {
            state.error = false;
            state.success = false;
            state.fetching = false;
        }
    }
})

export default processMonitorSlice.reducer;
export const { setLoading, setError, setSuccess, resetMessage, setFetching } = processMonitorSlice.actions