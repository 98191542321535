import Spinner from 'assets/spinner.svg'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import { CustomInput, CustomTextBox, CustomSelect } from 'components/inputs'
import Modal from 'components/modal/index';
import { addEvent } from 'features/events';
import { useDispatch, useSelector } from 'react-redux';
import { resetMessage } from '../../features/processMonitor';
import { toast } from 'react-toastify';

const CreateEventModal = ({ modalOpen, handleClose }) => {

    const {success, error, loading} = useSelector(state => state.monitor)
    const dispatch = useDispatch()

    const studentInitialDetails = {
        title: '',
        audiance: '',
        date: '',
        details: '',
    }

    const studentDetailsValidationSchema = Yup.object({
        title: Yup.string().required('Event title is required'),
        audiance: Yup.string().required('Audiance is required'),
        date: Yup.string().required('Event date is required'),
        details: Yup.string().required('Event details are required'),
    })

    const submitHandler = (values) => {
        dispatch(addEvent(values))
        setTimeout(() => {
            dispatch(resetMessage())
        }, 300);
    }

    return <Modal isOpen={modalOpen} handleClose={handleClose}>
        <div className='pb-5 mb-5 flex flex-row items-center border-b-2 border-slate-300'>
            <h5 className='text-violet font-semibold capitalize text-lg'>events</h5>
            <p className='text-2xl px-2 text-black'>&#10147;</p>
            <h5 className='text-violet capitalize text-lg'>create</h5>
        </div>
        <Formik
            initialValues={studentInitialDetails} validationSchema={studentDetailsValidationSchema}
            onSubmit={(values) => submitHandler(values)}>
            <Form>
                <CustomInput name='title' label='event title' type='dtextte' placeholder='Events title' />
                <CustomSelect name='audiance' label='audiance'>
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                </CustomSelect>
                <CustomInput name='date' label='event date' type='date' />
                <CustomTextBox name='details' label='Events details' placeholder='Events details' />
                <button className='flex flex-row items-center bg-violet border-0 rounded-3xl px-6 py-2 text-white active:bg-violet active:shadow-lg transition duration-150 ease-in-out capitalize' type='submit'>
                    save event
                </button>
            </Form>
        </Formik>
    </Modal>
}


export default CreateEventModal