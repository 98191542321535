import React, { Fragment } from 'react';
import Dashboard from 'modules/dashboard/index';
import Events from 'modules/events/index';
import Announcements from 'modules/announcements/index';
import Students from 'modules/students/index';
import Users from 'modules/users/index';
import Header from 'components/header/index';
import { useSelector } from 'react-redux';

const Maincontainer = () => {
    const { currentRoute } = useSelector(state => state.user)
    return (
        <Fragment>
            <Header/>
            <div className='h-[calc(100%-64px)] overflow-y-auto'>
                {
                    currentRoute === 'dashboard' ? <Dashboard /> :
                    currentRoute === 'events' ? <Events /> :
                    currentRoute === 'announcements' ? <Announcements /> :
                    currentRoute === 'students' ? <Students /> :
                    currentRoute === 'users' ? <Users /> :
                                        null
                }
            </div>
        </Fragment>
    );
}


export default Maincontainer;
