import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEvents } from 'features/events';
import { getAnnouncements } from 'features/announcements';
import { getStudents } from 'features/student';
import { getUsers } from 'features/user';
import SideMenu from 'components/sideMenu/index';
import Maincontainer from 'components/mainContainer/index';
import SplashScreen from 'pages/splashScreen';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router';

const Application = () => {
    const dispatch = useDispatch()
    const { fetching } = useSelector(state => state.monitor)

    const navigate = useNavigate()

    useEffect(() => {
        if (!Cookies.get(process.env.REACT_APP_COOKIE)) {
            navigate('/auth', { replace: true })
        } else {
            dispatch(getEvents())
            dispatch(getAnnouncements())
            dispatch(getStudents())
            dispatch(getUsers())
        }
        return () => { };
    }, []);

    // if (fetching) return (<SplashScreen />);
    return (
        <div className='w-screen h-screen flex flex-row fixed'>
            <div className='basis-64 h-full'>
                <SideMenu />
            </div>
            <div className='w-[calc(100%-256px)] h-full bg-white px-6 py-6'>
                <Maincontainer />
            </div>
        </div>
    );
}

export default Application;
