import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ID } from 'appwrite';
import { databases } from 'backend/config';
import { setFetching, setLoading, setError, setSuccess, resetMessage } from 'features/processMonitor'
import { toast } from 'react-toastify';

const initialState = {
    events: []
}

export const getEvents = createAsyncThunk('events/getEvents', async (_, thunkAPI) => {
    try {
        thunkAPI.dispatch(setFetching())
        let events = await databases.listDocuments(process.env.REACT_APP_DB, 'events');
        thunkAPI.dispatch(setSuccess())
        return events.documents;
    } catch (error) {
        thunkAPI.dispatch(setError(error.message))
    } finally {
        setTimeout(() => {
            thunkAPI.dispatch(resetMessage())
        }, 5000);
    }
})

export const addEvent = createAsyncThunk('events/addEvent', async (values, thunkAPI) => {
    const notification  = toast.loading('Saving events...', {toastId: 'event-notification'});
    try {
        databases.createDocument(process.env.REACT_APP_DB, 'events', ID.unique(), {
            title: values.title,
            audiance: values.audiance,
            date: values.date,
            details: values.details
        });
        toast.update(notification, {render: 'Event added sucessfully', type: 'success', isLoading: false, autoClose: 2000})
        thunkAPI.dispatch(setSuccess())
    } catch (error) {
        toast.update(notification, {render: error.message, type: 'error', isLoading: false, autoClose: 2000})
        thunkAPI.dispatch(setError(error.message))
    }
})

const eventsSlice = createSlice({
    name: 'events',
    initialState,
    extraReducers: {
        [getEvents.fulfilled]: (state, { payload }) => {
            state.events = payload
        },
        [addEvent.fulfilled]: (state, { payload }) => {
            console.log(payload)
        }
    }
})

export default eventsSlice.reducer