import React, { useEffect } from 'react';
import Logo from 'assets/images/logo.png'
import * as Yup from 'yup'
import { Field, Formik, Form } from 'formik';
import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { userLogin } from '../features/user';

const Auth = () => {
    const dispatch = useDispatch()
    const { loading, success } = useSelector(store => store.user)

    const authValidationSchema = Yup.object({
        email: Yup.string().email('Enter a valid email address').required('Email is required'),
        password: Yup.string().required('Password is required')
    })

    const navigate = useNavigate()

    const loginHandler = ({email, password}) => dispatch(userLogin({email, password}))

    useEffect(() => {
        if (success) navigate('/app', { replace: true })
        return () => {};
    }, [success]);

    return (
        <div className='w-screen h-screen flex flex-row'>
            <ToastContainer />
            <div className='basis-1/2 h-full bg-cover w-full bg-banner1' />
            <div className='basis-1/2 h-full w-full flex flex-col items-center justify-center'>
                <div className='w-8/12'>
                    <div className='flex flex-col items-center mb-10'>
                        <img src={Logo} alt="Logo" className='mb-8 h-[112px] w-[358px]' />
                        <h3 className='text-[58px]'>Sign In</h3>
                    </div>
                    <Formik initialValues={{
                        email: '',
                        password: ''
                    }} validationSchema={authValidationSchema} onSubmit={values => loginHandler(values)}>
                        {({ errors, touched }) => <Form>
                            <div className="relative">
                                <div className='w3-full'>
                                    <label class="block mb-1 text-[17px]">Email</label>
                                    <Field name='email' className="w-full h-12 pl-3 pr-15 text-base placeholder-gray-600 bg-grey focus:shadow-outline" type="email" placeholder="example@youemail.com" />
                                    {errors.email && touched.email ? <h5 className='text-red-600 mt-2'>{errors.email}</h5> : null}
                                </div>
                                <div className="absolute inset-y-14 right-0 flex items-center px-2 pointer-events-none">
                                </div>
                            </div>
                            <div className="relative mt-7">
                                <div className='w3-full'>
                                    <label class="block mb-1 text-[17px]">Password</label>
                                    <Field name='password' className="w-full h-12 pl-3 pr-15 text-base placeholder-gray-600 bg-grey focus:shadow-outline" type="password" placeholder="password" />
                                    {errors.password && touched.password ? <h5 className='text-red-600 mt-2'>{errors.password}</h5> : null}
                                </div>
                                <div className="absolute inset-y-14 right-0 flex items-center px-2 pointer-events-none">

                                </div>
                            </div>
                            <div className='mt-10'>
                                <button disabled={loading} className='bg-violet w-full h-[54px] text-[19px] rounded-md text-white active:bg-violet active:shadow-lg transition duration-150 ease-in-out capitalize' type='submit'>
                                    log in
                                </button>
                            </div>
                            <div className='inline-flex mt-2'>
                                <h5>Don't have an account ?</h5><h5 className='ml-2 text-violet'>Contact Admin</h5>
                            </div>
                        </Form>}
                    </Formik>

                </div>
            </div>
        </div>
    );
}

export default Auth;
