import React, { useEffect, useState } from 'react';
import { getUsers } from 'features/user';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from '../../helpers';
import passport from 'assets/images/passport place holder.jpeg'
import Edit from 'assets/icons/edit.svg'
import Delete from 'assets/icons/delete.svg'
import CreateUserModal from './createUserModal';

const Users = () => {
    const dispatch = useDispatch()
    const { users } = useSelector(state => state.user)
    const [showaddusermodal, setshowaddusermodal] = useState(false)

    useEffect(() => {
        dispatch(getUsers())
        return () => { };
    }, []);

    return (
        <>
            {showaddusermodal && <CreateUserModal modalOpen={showaddusermodal} handleClose={setshowaddusermodal} />}
            <div className='mb-2 h-12 text-right'>
                <button className='px-5 py-2 border-2 border-violet rounded-3xl text-violet capitalize' onClick={() => setshowaddusermodal(true)}>
                    add user
                </button>
            </div>
            <div className='w-full bg-[#ffffff] min-h-[calc(100%-56px)] px-8 pt-8 pb-12'>
                <div className='flex flex-row justify-evenly mb-4 px-4 border-b-[1.5px] border-slate-200 pb-4'>
                    {
                        ['id', 'name', 'email', 'role', 'create date', 'action'].map((f, index) => <div className='w-full' key={index}>
                            <h5 className='capitalize font-semibold'>{f}</h5>
                        </div>)
                    }
                </div>
                {
                    users && users.length > 0 ? users.map(({ $id, firstName, lastName, email, role, createdAt }, index) => <div key={$id} className={`flex flex-row justify-evenly items-center px-4 py-3 ${(index % 2) && `bg-violet bg-opacity-20`}`}>
                        <div className='w-full'>
                            <img src={passport} className='rounded-full w-[45px] h-[45px]' />
                        </div>
                        <div className='w-full'><h5 className='capitalize text-gray-500 tracking-wide'>{`${firstName} ${lastName}`}</h5></div>
                        <div className='w-full'><h5 className='text-gray-500'>{email}</h5></div>
                        <div className='w-full'><h5 className='capitalize text-gray-500 tracking-wide'>{role}</h5></div>
                        <div className='w-full'><h5 className='capitalize text-gray-500'>{formatDate(createdAt)}</h5></div>
                        <div className='inline-flex w-full'>
                            <img src={Edit} className='h-[40px] w-[40px] p-2 mr-1 cursor-pointer rounded-full bg-[#fff] ' />
                            <img src={Delete} className='h-[40px] w-[40px] p-2 mr-1 cursor-pointer rounded-full bg-[#fff] ' /></div>
                    </div>) : <div className='bg-[#fff] h-full py-8 grid place-content-center place-items-center capitalize text-gray-500 font-semibold text-lg'>No users found</div>
                }
            </div>
        </>
    );
}

export default Users;
