import React, { Fragment } from 'react';
import Logo from 'assets/images/logo.png'
import Home from 'assets/icons/home.svg'
import Bell from 'assets/icons/bell.svg'
import Calendar from 'assets/icons/calendar.svg'
import Student from 'assets/icons/student.svg'
import Teacher from 'assets/icons/teacher.svg'
import User from 'assets/icons/user.svg'
import { useDispatch, useSelector } from 'react-redux';
import { switchroute } from '../../features/user';

const side_menu_content = [
    {
        name: 'dashboard',
        icon: Home
    },
    {
        name: 'events',
        icon: Calendar
    },
    {
        name: 'announcements',
        icon: Bell
    },
    {
        name: 'students',
        icon: Student
    },
    {
        name: 'users',
        icon: User
    }
];

const SideMenu = () => {
    const dispatch = useDispatch()
    const { currentRoute } = useSelector(state => state.user)

    return (
        <Fragment>
            <div className='flex flex-col items-center justify-center h-48'>
                <img src={Logo} alt="Logo" className='h-3/5 w-full' />
            </div>
            <div className='bg-violet h-[calc(100vh-192px)] py-4'>
                {side_menu_content.map((f, index) => <div key={index} className={currentRoute === f.name ? `w-full inline-flex py-2 ml-5 px-5 cursor-pointer bg-white rounded-tl-3xl rounded-bl-3xl` : `w-full inline-flex py-5 ml-5 px-5 cursor-pointer`} onClick={() => dispatch(switchroute(f.name))}>
                    <div>
                        <img src={f.icon} className='h-[30px] w-[30px]' style={currentRoute === f.name ? null : { filter: 'brightness(0) invert(1)' }} />
                    </div>
                    <div className='h-[30px] ml-3 flex items-center'>
                        <h4 className={`tracking-wider capitalize ${currentRoute === f.name ? `text-violet` : `text-white`}`}>{f.name}</h4>
                    </div>
                </div>)}
            </div>
        </Fragment>
    );
}

export default SideMenu;
