import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getStudents } from 'features/student';
import ListStudents from 'modules/students/listStudents';
import CreateStudent from 'modules/students/createStudent';
import StudentDetails from 'modules/students/studentsDetails';
import EditStudent from 'modules/students/editStudent';

const Students = () => {

    const dispatch = useDispatch()
    const [list, setList] = useState(true)
    const [selectedStudentId, setSelectedStudentId] = useState('')

    const changeHandler = (e) => {
        e.preventDefault()
        typeof list === 'string' ? setList(true) :
            setList(prevState => {
                return !prevState
            })
    }

    useEffect(() => {
        dispatch(getStudents())
        return () => { };
    }, []);

    return (
        <Fragment>
            <div className='mb-2 h-12 text-right'>
                <button className='px-5 py-2 border-2 border-violet rounded-3xl text-violet capitalize' onClick={(e) => changeHandler(e)}>
                    {list & typeof list !== 'string' ? `add student` : `View students`}
                </button>
                {
                    list === 'student-details' && <button className='px-5 py-2 border-2 border-violet rounded-3xl text-violet capitalize ml-2' onClick={() => setList('edit-student')}>
                        edit student
                    </button>
                }
            </div>
            {
                list & typeof list !== 'string' ? <ListStudents toggleDetails={setList} setStudentId={setSelectedStudentId} /> :
                    typeof list !== 'string' & !list ? <CreateStudent /> :
                        list === 'student-details' ? <StudentDetails studentId={selectedStudentId} /> :
                            list === 'edit-student' ? <EditStudent studentId={selectedStudentId} /> :
                                null
            }
        </Fragment>

    );
}

export default Students;