import Logo from 'assets/images/logo.png'

const PageNotFound = () => {
    return (
        <div className='h-screen w-screen flex flex-col justify-center items-center'>
            <img className='h-[122px] w-[368px]' src={Logo} />
            <h4>Page Not Found</h4>
            <h5 className='text-violet cursor-pointer'>Go Home</h5>
        </div>
    );
}

export default PageNotFound;
