import React, { useState } from 'react';
import Bell from 'assets/icons/bell.svg'
import Cog from 'assets/icons/gear.svg'
import Call from 'assets/icons/Call.svg'
import Location from 'assets/icons/Location.svg'
import Calender from 'assets/icons/calendar.svg'
import User from 'assets/icons/user.svg'
import Progress from 'assets/icons/Activity.svg'
import Spinner from 'assets/spinner.svg'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import appwriteSdk from 'backend/config'
import { v4 as uuidv4 } from 'uuid';
import DetailsCard from 'components/cards/index';
import { CustomInput, CustomTextBox, CustomSelect } from 'components/inputs'
import { Error, Success } from 'components/notifiers'
import { useSelector, useDispatch } from 'react-redux';
import { addStudent } from 'features/student';






const EditStudent = ({ studentId }) => {

    const { students, parents } = useSelector(state => state.students);
    const studentsDetails = students.filter(t => t.studentID === studentId)[0]
    const parentsDetails = parents.filter(u => u.studentID === studentId)[0]


    const dispatch = useDispatch()
    const studentInitialDetails = {
        firstName: studentsDetails.firstName,
        lastName: studentsDetails.lastName,
        dob: studentsDetails.dob,
        placeOfBirth: studentsDetails.placeOfBirth,
        parentName: '',
        email: studentsDetails.email,
        phone: studentsDetails.phone,
        address: studentsDetails.address,
        parentFirstName: parentsDetails.firstName,
        parentLastName: parentsDetails.lastName,
        parentEmail: parentsDetails.email,
        parentPhone: parentsDetails.phone,
        parentAddress: parentsDetails.address
    }

    const studentDetailsValidationSchema = Yup.object({
        firstName: Yup.string().required('first name is required'),
        lastName: Yup.string().required('last name is required'),
        dob: Yup.string().required('date of birth is required'),
        placeOfBirth: Yup.string().required('place of birth is required'),
        parentName: Yup.string().required('parent\'s name is required'),
        email: Yup.string().required('email is required'),
        phone: Yup.string().required('phone number is required'),
        address: Yup.string().required('address is required'),
        parentFirstName: Yup.string().required('parent\'s first name is required'),
        parentLastName: Yup.string().required('parent\'s last name is required'),
        parentEmail: Yup.string().required('parent\'s email is required'),
        parentPhone: Yup.string().required('parent\'s phone number is required'),
        parentAddress: Yup.string().required('parent\'s address is required')
    })

    const submitHandler = (values) => dispatch(addStudent(values))

    return <Formik
        initialValues={studentInitialDetails} validationSchema={studentDetailsValidationSchema}
        onSubmit={(values) => submitHandler(values)}>
        <Form>
            <DetailsCard title="Student's details">
                <div className='basis-2/12'>
                    <label className="block mb-1 text-[17px]">Photo</label>
                    <div className='h-[150px] w-full border-2 px-2 cursor-pointer border-grey rounded-lg flex flex-row justify-center items-center'>
                        <h6 className='font-extralight'>Click here to select file</h6>
                    </div>
                </div>
                <div className='basis-5/12 px-5'>
                    <CustomInput name="firstName" label='first name' placeholder='First name' />
                    <div className='flex flex-row'>
                        <div className='basis-1/2'>
                            <CustomInput name='dob' label='date of birth' placeholder='01/01/1990' />
                        </div>
                        <div className='basis-1/2 ml-5'>
                            <CustomInput name="placeOfBirth" label='place of birth' placeholder='Place of birth' />
                        </div>
                    </div>
                    <CustomInput name='email' label='email' placeholder='exmaple@email.com' />
                    <CustomTextBox name='address' label='address' placeholder='Kololo, kampala' />
                </div>
                <div className='basis-5/12 px-5'>
                    <CustomInput name='lastName' label="last name" placeholder='Last Name' />
                    <CustomInput name='parentName' label='parent name' placeholder='Parent name' />
                    <CustomInput name='phone' label='phone' placeholder='+256-772-56-58-28' />
                </div>
            </DetailsCard>
            <DetailsCard title="Parent's details">
                <div className='basis-1/2'>
                    <CustomInput name='parentFirstName' label='first name' placeholder='First name' />
                    <CustomInput name='parentEmail' label='email' placeholder='exmaple@email.com' />
                    <CustomTextBox name='parentAddress' label='address' placeholder='Kololo, kampala' />
                </div>
                <div className='basis-1/2 ml-5'>
                    <CustomInput name='parentLastName' label='last name' placeholder='Last name' />
                    <CustomInput name='parentPhone' label='phone' placeholder='+256-772-56-58-28' />
                </div>
            </DetailsCard>
            {/* {error && <Error msg={error} />}
            {success && <Success msg='Student added' />} */}
            <button className='flex flex-row items-center bg-violet border-0 rounded-3xl px-6 py-2 text-white active:bg-violet active:shadow-lg transition duration-150 ease-in-out' type='submit'>
                {/* {processing && <img src={Spinner} className='w-[40px] h-[40px]' />} */}
                {/* {processing ? `Saving Student` : `Save Student`} */}
                save changes
            </button>
        </Form>
    </Formik>
}

export default EditStudent