import React from 'react'
import ReactDOM from 'react-dom/client'
import App from 'App.js'
import 'assets/css/index.css'
import 'react-image-crop/dist/ReactCrop.css'
import { Provider } from 'react-redux';
import store, { persistor } from 'store/index'
import { PersistGate } from 'redux-persist/integration/react'
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <Provider store={store}>
            {/* <PersistGate loading={null} persistor={persistor}> */}
                <App />
            {/* </PersistGate> */}
        </Provider>
    </React.StrictMode>
)
