import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import { CustomSelect, CustomInput } from 'components/inputs'
import Modal from 'components/modal/index';
import { useDispatch, useSelector } from 'react-redux';
import { addAnnouncement } from 'features/announcements';

const CreateUserModal = ({ modalOpen, handleClose }) => {
    const dispatch = useDispatch()
    const { loading, error, success } = useSelector(state => state.monitor)

    const userInititalValues = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        password2: '',
        role: '',
    }

    const userValidationSchema = Yup.object({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        email: Yup.string().required('Email is required'),
        password: Yup.string().required('Password is required'),
        password2: Yup.string().required('Password confirmation is required'),
        role: Yup.string().required('Role is required'),
    })

    const submitHandler = (values) => dispatch(addAnnouncement(values))

    return <Modal isOpen={modalOpen} handleClose={handleClose}>
        <div className='pb-5 mb-5 flex flex-row items-center border-b-2 border-slate-300'>
            <h5 className='text-violet font-semibold capitalize text-lg'>users</h5>
            <p className='text-2xl px-2 text-black'>&#10147;</p>
            <h5 className='text-violet capitalize text-lg'>add user</h5>
        </div>
        <Formik
            initialValues={userInititalValues} validationSchema={userValidationSchema}
            onSubmit={(values) => submitHandler(values)}>
            <Form>
            <CustomInput name="firstName" label='first name' placeholder='First name' />
            <CustomInput name="lastName" label='last name' placeholder='Last name' />
            <CustomInput name="email" label='email' type='email' placeholder='Last name' />
            <CustomInput name="password" label='password' type='password' placeholder='Password' />
            <CustomInput name="password2" label='confirm password' type='password' placeholder='Confrim password' />
                <CustomSelect name='role' label='role'>
                    <option>admin</option>
                    <option>teacher</option>
                    <option>student</option>
                </CustomSelect>
                <button disabled={loading} className='flex flex-row items-center bg-violet border-0 rounded-3xl px-6 py-2 text-white active:bg-violet active:shadow-lg transition duration-150 ease-in-out capitalize' type='submit'>
                    add user
                </button>
            </Form>
        </Formik>
    </Modal>
}

export default CreateUserModal