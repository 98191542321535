import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ID } from 'appwrite';
import { databases } from 'backend/config';
import {setFetching, setLoading, setError, setSuccess, resetMessage } from 'features/processMonitor'
import { v4 as uuidv4 } from 'uuid';

const initialState = {
    students: []
}

export const getStudents = createAsyncThunk('students/getStudents', async (_, thunkAPI) => {
    try {
        thunkAPI.dispatch(setFetching())
        let students = await databases.listDocuments(process.env.REACT_APP_DB, 'students');
        return { students: students.documents };
    } catch (error) {
        thunkAPI.dispatch(setError(error.message))
    } finally {
        setTimeout(() => {
            thunkAPI.dispatch(resetMessage())
        }, 5000);
    }
})

export const addStudent = createAsyncThunk('students/addStudent', async (data, thunkAPI) => {
    const studentID = uuidv4()
    const parentID = uuidv4()
    const _student = {
        studentID: studentID,
        firstName: data.firstName,
        lastName: data.lastName,
        dob: data.dob,
        placeOfBirth: data.placeOfBirth,
        email: data.email,
        phone: data.phone,
        address: data.address,
        parent: parentID,
        grade: data.studyLevel
    };
    const _parent = {
        parentID: parentID,
        firstName: data.parentFirstName,
        lastName: data.parentLastName,
        email: data.parentEmail,
        phone: data.parentPhone,
        address: data.parentAddress,
        studentID: studentID
    }
    try {
        thunkAPI.dispatch(setLoading())
        databases.createDocument(process.env.REACT_APP_DB, 'students', ID.unique(), _student)
        databases.createDocument(process.env.REACT_APP_DB, 'parents', ID.unique(), _parent)
        thunkAPI.dispatch(setSuccess())
        return { parent: _parent, student: _student }
    } catch (error) {
        thunkAPI.dispatch(setError())
    } finally {
        setTimeout(() => {
            thunkAPI.dispatch(resetMessage())
        }, 5000);
    }
})

const studentsSlice = createSlice({
    name: 'students',
    initialState,
    extraReducers: {
        [getStudents.fulfilled]: (state, { payload }) => {
            const { students } = payload
            state.students = students
        },
        [addStudent.fulfilled]: (state, { payload }) => {
            const { students } = payload
            state.students.push(students)
        }
    }
})

export default studentsSlice.reducer