import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {databases} from 'backend/config';
import { ID } from 'appwrite';
import { setFetching, setLoading, setError } from 'features/processMonitor'
import { toast } from 'react-toastify';


const initialState = {
    announcements: []
}

export const getAnnouncements = createAsyncThunk('announcemenets/getAnnouncements', async (_, thunkAPI) => {
    try {
        thunkAPI.dispatch(setFetching())
        let announcements = await databases.listDocuments(process.env.REACT_APP_DB, 'announcements');
        return announcements.documents;
    } catch (error) {
        thunkAPI.dispatch(setError(error.message))
    }
})

export const addAnnouncement = createAsyncThunk('announcements/addAnnouncement', async (values, thunkAPI) => {
    const notification  = toast.loading('Saving announcement...', {toastId: 'annoucement-notification'});
    try {
        thunkAPI.dispatch(setLoading())
        databases.createDocument(process.env.REACT_APP_DB, 'announcements', ID.unique(), {
            audiance: values.audiance,
            details: values.details
        });
        toast.update(notification, {render: 'Announcement added sucessfully', type: 'success', isLoading: false, autoClose: 2000})
    } catch (error) {
        toast.update(notification, {render: error.message, type: 'error', isLoading: false, autoClose: 2000})
        thunkAPI.dispatch(setError(error.message))
    }
})

const announcementsSlice = createSlice({
    name: 'announcements',
    initialState,
    extraReducers: {
        [getAnnouncements.fulfilled]: (state, { payload }) => {
            state.announcements = payload
        }
    }
})

export default announcementsSlice.reducer