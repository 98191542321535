import { createPortal } from 'react-dom';
import { useState } from 'react'
import { useLayoutEffect } from 'react';

function createWrapperAndAppendToBody(wrapperId) {
    const wrapperElement = document.createElement('div');
    wrapperElement.setAttribute("id", wrapperId);
    wrapperElement.style.height = '100vh';
    wrapperElement.style.width = '100%';
    wrapperElement.style.display = 'flex';
    wrapperElement.style.justifyContent = 'center';
    wrapperElement.style.alignItems = 'center';
    wrapperElement.style.backgroundColor = 'background';
    document.body.appendChild(wrapperElement);
    return wrapperElement;
}


const Portal = ({ children, wrapperId = "modal-portal" }) => {
    const [wrapperElement, setWrapperElement] = useState(null);

    useLayoutEffect(() => {
        let element = document.getElementById(wrapperId)
        let systemCreated = false;

        if (!element) {
            element = createWrapperAndAppendToBody(wrapperId);
        }
        setWrapperElement(element);

        return () => {
            if (systemCreated && element.parentNode) {
                element.parentNode.removeChild(element);
            }
        }
    }, [wrapperId])

    if (wrapperElement === null) return null;

    return createPortal(children, document.getElementById(wrapperId))
}

export default Portal;
