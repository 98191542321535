import React, { useState } from 'react';
import Bell from 'assets/icons/bell.svg'
import Cog from 'assets/icons/gear.svg'
import Call from 'assets/icons/Call.svg'
import Location from 'assets/icons/Location.svg'
import Calender from 'assets/icons/calendar.svg'
import User from 'assets/icons/user.svg'
import Progress from 'assets/icons/Activity.svg'
import Spinner from 'assets/spinner.svg'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import appwriteSdk from 'backend/config'
import { v4 as uuidv4 } from 'uuid';
import DetailsCard from 'components/cards/index';
import { CustomInput, CustomTextBox, CustomSelect } from 'components/inputs'
import { Error, Success } from 'components/notifiers'
import { useSelector, useDispatch } from 'react-redux';
import { addStudent } from 'features/student';
import passport from 'assets/images/passport place holder.jpeg'
import Edit from 'assets/icons/edit.svg'
import Delete from 'assets/icons/delete.svg'
import { formatDate } from '../../helpers';

const ListStudents = ({ toggleDetails, setStudentId }) => {
    const { students } = useSelector(state => state.students);

    const studentHanlder = id => {
        toggleDetails('student-details')
        setStudentId(id)
    }
    // if (typeof students === 'undefined') return null;

    return <div className='w-full rounded-lg bg-[#ffffff] min-h-[calc(100%-56px)] px-8 pt-8 pb-12'>
        <div className='flex flex-row justify-evenly border-b pb-4'>
            <div className='w-1/4'>
                <input type='checkbox' />
            </div>
            {
                ['id', 'names', 'dob', 'parent name', 'city', 'contact', 'grade', 'action'].map((f, index) => <div className='w-full' key={index}>
                    <h5 className='capitalize font-semibold'>{f}</h5>
                </div>)
            }
        </div>
        {
            students && students.length > 0 ? students.map((t, index) => <div key={t.$id} className={`flex flex-row justify-center items-center px-4 py-3 ${(index % 2) && `bg-violet bg-opacity-20`}`} onClick={() => studentHanlder(t.studentID)}>
                <div className='w-full'>
                    <img src={passport} className='rounded-full w-[45px] h-[45px]' />
                </div>
                <div className='w-full'>
                    <h5 className='capitalize text-gray-500 tracking-wide'>{`${t.firstName} ${t.lastName}`}</h5>
                </div>
                <div className='w-full'>
                    <h5 className='capitalize text-gray-500 tracking-wide'>{formatDate(t.dob)}</h5>
                </div>
                <div className='w-full'>
                    <h5 className='capitalize text-gray-500 tracking-wide'>{`${t.parent.firstName} ${t.parent.lastName}`}</h5>
                </div>
                <div className='w-full'>
                    <h5 className='capitalize text-gray-500 tracking-wide'>{t.address}</h5>
                </div>
                <div className='inline-block w-full'>
                    <h5 className='capitalize text-gray-500 tracking-wide'>{t.phoneNumber}</h5>
                    <h5 className='text-gray-500 tracking-wide'>{t.email}</h5>
                </div>
                <div className='w-full'>
                    <button className="bg-orange py-1 capitalize rounded-3xl px-4 text-white">{`grade ${t.grade}`}</button>
                </div>
                <div className='inline-flex w-full'>
                    <img src={Edit} className='h-[40px] w-[40px] p-2 mr-1 cursor-pointer rounded-full bg-[#fff] ' />
                    <img src={Delete} className='h-[40px] w-[40px] p-2 mr-1 cursor-pointer rounded-full bg-[#fff] ' />
                </div>
            </div>
            ) : <div className='bg-[#fff] h-full py-8 grid place-content-center place-items-center capitalize text-gray-500 font-semibold text-lg'>No students found</div>
        }

    </div>
}

export default ListStudents