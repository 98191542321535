import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import { CustomTextBox, CustomSelect } from 'components/inputs'
import Modal from 'components/modal/index';
import { useDispatch, useSelector } from 'react-redux';
import { addAnnouncement } from 'features/announcements';

const CreateAnnouncementModal = ({ modalOpen, handleClose }) => {
    const dispatch = useDispatch()
    const { loading, error, success } = useSelector(state => state.monitor)

    const studentInitialDetails = {
        audiance: '',
        details: '',
    }

    const studentDetailsValidationSchema = Yup.object({
        audiance: Yup.string().required('Select your audiance'),
        details: Yup.string().required('Announcement details are required'),
    })

    const submitHandler = (values) => dispatch(addAnnouncement(values))

    return <Modal isOpen={modalOpen} handleClose={handleClose}>
        <div className='pb-5 mb-5 flex flex-row items-center border-b-2 border-slate-300'>
            <h5 className='text-violet font-semibold capitalize text-lg'>announcements</h5>
            <p className='text-2xl px-2 text-black'>&#10147;</p>
            <h5 className='text-violet capitalize text-lg'>create</h5>
        </div>
        <Formik
            initialValues={studentInitialDetails} validationSchema={studentDetailsValidationSchema}
            onSubmit={(values) => submitHandler(values)}>
            <Form>
                <CustomSelect name='audiance' label='audiance'>
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                </CustomSelect>
                <CustomTextBox name='details' label='announcement' placeholder='Announcement details' />
                <button disabled={loading} className='flex flex-row items-center bg-violet border-0 rounded-3xl px-6 py-2 text-white active:bg-violet active:shadow-lg transition duration-150 ease-in-out capitalize' type='submit'>
                    save announcement
                </button>
            </Form>
        </Formik>
    </Modal>
}

export default CreateAnnouncementModal