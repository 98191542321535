import Modal from 'components/modal/index';
import { useState, useRef } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const ImageCrop = ({ modalOpen, handleClose }) => {
    const [src, setSrc] = useState(null)
    const [crop, setCrop] = useState({
        unit: '%',
        width: 75,
        height: 50,
        aspect: 16 / 9
    })

    const fileRef = useRef()
    const imageRef = useRef()
    const fileUrl = null;

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    const onImageLoaded = image => imageRef = image

    const onCropComplete = crop => makeClientCrop(crop)

    const onChange = (crop, percentCrop) => setCrop(crop)

    const makeClientCrop = async crop => {
        if (imageRef && crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImage(
                imageRef, crop, 'newfile.jpeg'
            )
            setCrop(prevState => {
                return { croppedImageUrl, ...prevState }
            })
        }
    }

    const getCroppedImage = (image, crop, fileName) => {
        const canvas = document.createElement('canvas')
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalwidth / image.width;
        const scaleY = image.naturalHeight / image.height
        const ctx = canvas.getContext('2d')

        canvas.width = crop.width * pixelRatio * scaleX
        canvas.height = crop.height * pixelRatio * scaleY
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
        ctx.imageSmoothingQuality = 'high'

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        )

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    console.log('canvas is empty')
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(fileUrl)
                fileUrl = window.URL.createObjectURL(blob)
                resolve(fileUrl)
            },
                'image/jpeg',
                1
            )
        })
    }

    const handleSubmit = () => console.log('hacking is key')

    return <Modal isOpen={modalOpen} handleClose={handleClose}>
        <div className='p-8'>
            hacking is key
        </div>
    </Modal>
    // return <Modal>
    //     <div>
    //         <input type="file" ref={this.fileRef} hidden accept="image/*" onChange={this.onSelectFile} />
    //     </div>
    //     <div className='w3-row-padding w3-full-height'>
    //         {
    //             src === null && <div className='w3-display-container w3-pointer w3-grey w3-mobile w3-full-width' style={{ height: '400px' }} onClick={() => this.fileRef.current.click()}>
    //                 <div className='w3-display-middle'>
    //                     <FontAwesomeIcon color="#fff" style={{ fontSize: '50px', margin: '15px' }} icon={faCamera} />
    //                 </div>
    //             </div>
    //         }
    //         {
    //             src !== null && (<>
    //                 <div className='w3-col l6 w3-full-height'>
    //                     {src && (
    //                         <ReactCrop
    //                             src={src}
    //                             crop={crop}
    //                             ruleOfThirds
    //                             onImageLoaded={this.onImageLoaded}
    //                             onComplete={this.onCropComplete}
    //                             onChange={this.onCropChange}
    //                             style={{ height: '100%', width: '100%' }}
    //                         />
    //                     )}
    //                 </div>
    //                 <div className='w3-col l6 w3-full-height'>
    //                     {croppedImageUrl && (
    //                         <img alt="Crop" style={{ maxWidth: '100%', }} src={croppedImageUrl} />
    //                     )}
    //                 </div></>)
    //         }
    //     </div>
    //     <div className='w3-row-padding w3-margin-top'>
    //         <div className='w3-col l6'><Button onClick={this.handleSubmit}>Save</Button></div>
    //         {src && <div className='w3-col l6'><Button className='w3-orange w3-text-white' onClick={() => this.fileRef.current.click()}>Change</Button></div>}
    //     </div>
    // </Modal>
}

export default ImageCrop